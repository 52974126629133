import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import * as btn from 'modules/app/styles/Button.styles';

export const root = css`
  background: hsl(var(--color-text-1));
  color: hsl(var(--color-grayscale-1));
  height: 100%;
`;

export const content = css`
  padding: ${spacing(120)} 0;
  max-width: 528px;
  margin: 0 auto;
`;

export const label = css`
  letter-spacing: 1.25px;
  ${font.size.tiny};
  text-transform: uppercase;
  color: hsl(var(--color-grayscale-4));
  margin-bottom: ${spacing(20)};
`;

export const text = css`
  ${font.size.small};
  color: hsl(var(--color-grayscale-4));

  :first-of-type {
    margin-bottom: ${spacing(12)};
  }
`;

export const info = css`
  color: hsl(var(--color-note-8));
  ${font.size.small};
  ${font.weight.bold};
  margin-bottom: ${spacing(12)};
`;

export const company = css`
  display: flex;
  align-items: center;
  padding-bottom: ${spacing(40)};
  margin: ${spacing(20)} 0 ${spacing(40)};
  border-bottom: 1px solid hsl(var(--color-borderBlack-2));

  > p {
    margin-left: ${spacing(16)};
    ${font.size.tiny};
  }
`;

export const link = css`
  ${btn.root};
  ${btn.secondary};
  ${btn.tiny};
  margin-top: ${spacing(40)};
`;
